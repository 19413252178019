<template>
    <section>
        <modal ref="CalificacionesPedido" titulo="Calificación del Pedido" icon="review" no-aceptar no-cancelar :btns="buttons"> 
            <template v-slot:header-slot>
                <div class="col-auto d-middle-center mb-1 text-general f-17">
                    {{ pedido.calificacion }} <i class="icon-star f-20 orange-star" />
                </div>
            </template>
            <hr class="my-3" />
            <div v-for="(calificacion, c) in calificaciones" :key="c" class="row mx-0 px-5 py-2 border-bottom">
                <div class="col-4">
                    <p class="d-middle">
                        {{ calificacion.nombre }}
                    </p>
                </div>
                <div class="col-6">
                    <el-rate
                    v-model="calificacion.calificacion"
                    disabled
                    score-template="{value}"
                    />
                </div>
            </div>
            <div class="col-12">
                <p class="text-muted2 f-15 text-center">
                    {{ _.defaultTo(pedido.calificacion_comentario,'Sin comentario') }}
                </p>
            </div>
        </modal>
    </section>
</template>
<script>
import Calificaciones from "~/services/calificaciones";
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            icono: ['icon-star'],
            calificaciones: [],
            id_pedido: null,
            pedido: {
                id: null,
                calificacion: null,
                calificacion_comentario: null
            },
            buttons: [
                {texto: 'Cerrar', color: 'bg-light-f5 border text-general2'}
            ]
        }
    },
    computed: {
        ...mapGetters({

        }),
        caso(){
            return 0
        }
    },
    methods: {
        toggle(id){
            this.id_pedido = id
            this.$refs.CalificacionesPedido.toggle();
            this.get_calificaciones()
        },
        async get_calificaciones(){
            try {
                let params = {
                    caso: 2,
                }
                const { data } = await Calificaciones.get_calificaciones(this.id_pedido, params)
                this.calificaciones = data.data.calificaciones
                this.pedido = data.data.pedido
            } catch (error){
                this.error_catch(error)
            }
        },
        cerrar(){
            this.$refs.CalificacionesPedido.toggle()
        }
    }
}
</script>
<style lang="css" scoped>
.orange-star{
    color: #FF9D32;
}
</style>
